<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>我曾留学于美国，一直就职于美资企业，与妻子相识相恋于美国，并在美国结婚、生下我们的大儿子。多年来的从业经验让我对美国有了深刻的了解。
          但是在美毕业后，并没有想强烈的愿望在美国留下来，于是携妻子、孩子回到中国生活、工作。但在中国生活工作几年，我们又有了小女儿，我和妻子越来越怀念美国的日子，
          妻子和我都希望回美国发展。在与妻子商议后，我们全家决定移民美国。</p>
        <p>在中介方面我和妻子都决定慎重选择，经上网查询和朋友间推荐，我们发现跃迁是该行业执牛耳者：他们对于美国的移民政策非常熟悉
            我当即拨打跃迁出国的电话，跃迁的客服人员很快为我安排现场咨询。</p>
        <p>在与顾问的交流中，我得知我的移民优势很明显。在多方权衡之后，顾问向我推荐波特兰万豪酒店项目。我与妻子工作多年，略有积蓄，
          但手头现金无法支付50万美金的投资款。跃迁顾问列出多种资金方案，每种方案跟我们细细分析，终我们决定出售名下一套房产，用以凑足50万美金的现金。
          我们听从顾问建议，采取夫妻薪资积累+售房款的双拼资金来源方案筹足资金开始进行投资移民。签约后，跃迁顾问为我制定了详细的投资计划，
          每到重要时间节点都会提前电话通知我。并且，我和妻子白日工作都很繁忙，无论我们多晚向顾问咨询，顾问都会耐心解答。他们的专业水平、耐心细致和周到的服务深深地打动了我。
          整个过程非常顺利，这多亏了跃迁顾问团队的帮助。目前我们全家已经在美国生活近2年了，也正准备递交了I829申请。波特兰万豪酒店也非常成功，项目分红也已经拿到了，且酒店运营非常棒，永久绿卡也得到了保障。再次感谢跃迁顾问的专业服务。<br>
        </p>
        <p>
          <img loading="lazy" class="aligncenter" src="@/assets/Cases/4/1.jpg">
        </p>
      </div>
      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases6",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>